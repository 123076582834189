import { reactive } from 'vue';
import { StoreBase, StoreType } from './store-base';

export type HelpTypes = 'actor' | 'practical' | 'other';

export type ActorRoles =
    'camel' |
    'josefs-angel' |
    'angel-who-shouts' |
    'soldier' |
    'shepherd' |
    'lamb' |
    'seller' |
    'scribe' |
    'innkeeper' |
    'glitter-blower';

/**
 * The store interface
 */
export interface StoreData extends Object {
    name?: string;
    age?: number;
    helpType?: HelpTypes;
    actorRoles?: ActorRoles[];
}

class Store extends StoreBase<StoreData> {
    constructor() {
        super(StoreType.memory, 'form');
    }

    protected defaultData(): StoreData {
        return {
        };
    }

    public setName(name: string) {
        this.reactiveState.name = name;
    }

    public setAge(age: number) {
        this.reactiveState.age = age;
    }

    public setHelpType(helpType: HelpTypes) {
        this.reactiveState.helpType = helpType;
    }

    public setActorRoles(actorRoles: ActorRoles[]) {
        this.reactiveState.actorRoles = actorRoles;
    }

    public clearStore() {
        this.reactiveState = reactive(this.defaultData());
    }
}

const store: Store = new Store();
export default store;