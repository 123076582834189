import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Page1Welcome from '@/views/page.1.welcome.vue'
import Page2Name from '@/views/page.2.name.vue'
import Page3HelpType from '@/views/page.3.help-type.vue'
import Page4Summary from '@/views/page.4.summary.vue'
import Page5ThankYou from '@/views/page.5.thank-you.vue'
import Actor1Roles from '@/views/actor/actor.1.roles.vue'
import Other from '@/views/other/other.1.text.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Page1Welcome
    },
    {
        path: '/name',
        component: Page2Name
    },
    {
        path: '/help-type',
        component: Page3HelpType
    },
    {
        path: '/summary',
        component: Page4Summary
    },
    {
        path: '/thank-you',
        component: Page5ThankYou
    },
    {
        path: '/actor',
        component: Actor1Roles
    },
    {
        path: '/other',
        component: Other
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
